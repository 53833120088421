<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :fullscreen="isMobile"
    width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="not-rounded"
        :large="isDesktop"
        :block="isDesktop"
        :outlined="!isDesktop"
        :icon="!isDesktop"
        v-bind="attrs"
        v-on="on"
        @click='toggleTooltip'
      >
        <span v-if="isDesktop">{{$t('root.buttons.about')}}</span>
        <v-icon
          v-else
          v-bind="attrs"
          v-on="on"
          :large="true"
          color="#000000"
        >
          mdi-information-symbol
        </v-icon>
      </v-btn>
    </template>
    <template>
      <v-card v-if="textContent">
        <v-card-title class="info-title justify-space-between">
          {{textContent[contentName].title}}
          <v-btn
            color="normal"
            icon
            @click='toggleTooltip'
          >
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="info-content undp-typography small-font undp-style" v-html="textContent[contentName].content">
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import sizeMixin from '@/mixins/size.mixin'

// import { mapState } from 'vuex';
export default {
  name: 'InfoButton',
  mixins: [sizeMixin],
  data() {
    return {
      dialog: false
    }
  },
  props:['contentName', 'fab'],
  computed: {
    textContent() {
      return this.$t('tooltips')
    }
  },
  methods: {
    toggleTooltip() {
      this.dialog = !this.dialog
    }
  }
}
</script>

<style>
.info-title{
  position: relative;
  padding-bottom: 40px !important;
  background: #fff;
  height: 46px;
}
.info-title::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  height: 4px;
  background: linear-gradient(90deg, rgba(13,177,75,1) 0%, rgba(13,177,75,1) 33%, rgba(245,130,32,1) 33%, rgba(245,130,32,1) 66%, rgba(11,198,255,1) 66%, rgba(0,212,255,1) 100%);
}
.info-title {
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}
</style>
